
import td_collect_empty from './img/td_collect_empty.png'
import td_collect_full from './img/td_collect_full.png'
// import loginPop from '../../../../../cmps/loginPop/loginPop'
var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || u.indexOf("iPhone") > -1 || u.indexOf('iPad') > -1 || u.indexOf("iOS") > -1; //ios终端

Date.prototype.formatNoS=function (){
  var s='';
  s+=this.getFullYear()+'-';          // 获取年份。
  s+=(this.getMonth()+1)+"-";         // 获取月份。
  s+= this.getDate();                 // 获取日。
  return(s);                          // 返回日期。
};

//按日查询
function getDayAll(begin,end){
  var dateAllArr = new Array();
  var ab = begin.split("-");
  var ae = end.split("-");
  var db = new Date();
  db.setUTCFullYear(ab[0], ab[1]-1, ab[2]);
  var de = new Date();
  de.setUTCFullYear(ae[0], ae[1]-1, ae[2]);
  var unixDb=db.getTime();
  var unixDe=de.getTime();
  for(var k=unixDb;k<=unixDe;){
    dateAllArr.push((new Date(parseInt(k))).formatNoS().toString());
    k=k+24*60*60*1000;
  }
  return dateAllArr;
}
import { Swipe, SwipeItem, Rate, ActionSheet, Calendar, Icon, Toast, Image } from 'vant'

export default {
  components:{
    'van-image': Image,
    'van-icon': Icon,
    'van-calendar': Calendar,
    'van-action-sheet': ActionSheet,
    'van-rate': Rate,
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem,
  },
  data () {
    return {
      td_collect_empty,
      td_collect_full,
      apis:{
        scenic:'/seller/find',//获取景点信息
        types:'/commodity/queryCommodityTypeBySellerId',//商品类型ID
        tickets: '/commodity/queryCommodity',//商家商品列表
        queryThirdSystemCode: '/commodity/queryThirdSystemCode',//时间段
        findPriceCalendarByCommodityId: '/commodity/findPriceCalendarByCommodityId',//价格日历
        slicedQueryUserCriticism: '/userCriticism/slicedQueryUserCriticism',//评论
        addCollect:'/userCollect/addCollect',//取消点赞
        cancelCollect:'/userCollect/cancelMyCollect',//取消点赞
        getRemainTicket:'/seller/getRemainTicket',//获取余票
      },
      bizType: 3,//商家固定的业务类型
      scenicKey: null,//景区ID
      scenic:{},//景区信息
      imgs:[],//景区图片列表
      videos:[],//视频列表
      colorState:false,
      types:[],//门票类型
      ticketParams:{//门票查询参数
        sellerId: null,//商家ID
        commodityTypeId:null,//分类ID
        name:null,//商品组名称
        type:null,//类型
      },
      tickets:[],//门票列表
      playing:false,//是否正在播放音频
      expandContent:false,//值为true时完整展示简介，否则只展示两行
      loactions:null,//手机定位地址
      current:0,//轮播index
      isCollect:0,//是否收藏
      showHeadBox:false,//是否展示头部盒子
      rateValue:3.5,//星际
      showBuyDate:false,//购买日期弹窗
      showDatePicker:false,//日历弹窗
      buyTitle:'',
      dateArr:[],//价格日历
      timeArr:[],//时间段
      chooseDate:null,//选择日期active
      chooseTime:null,//选择时间段active
      cDate:null,//选择的日期
      cTime:null,//选择的时间段
      cTimeId:null,//第三方商品库ID
      cPrice:null,//第三方商品价格
      typeName:'',//类型名称
      remark:'',//票备注
      commentArr: [],//评论数组
      commodityTypeId: '',//当前你分类id
      commodityIdFinal: '',//最终需要传给下单的商品id
      showQrPop:false,//景区预约弹窗
      remainList:[],//余票列表
      isShowLoginPop:false,
      snowId:'1042313',
      timeDate:null,
      urlWX:'',//获取签名的url地址
      depiction:'',
      remarkShow:false,  //  商品详情展示按钮
      sheetContent:{},  //  商品详情
    }
  },
  mounted() {
    let me = this;
    me.scenicKey  = me.$route.params.id;
    let isSnow = me.snowId == me.scenicKey ? 1 : 0;
    sessionStorage.setItem('isSnow',isSnow);
    me.loadScenic();
    me.loadTypes();
    me.getComment();
    // this.getDetail()
  },
  created(){
    localStorage.setItem('snowId',this.snowId)
  },
  methods: {
    closeBuyDate(){
      this.showBuyDate = false;
      this.timeDate = null;
    },
    getRemainTicket(sellerThirdCode){//获取余票
      const me = this;
      me.$get({
        url: me.apis.getRemainTicket,
        params:{
          sellerThirdCode:sellerThirdCode,
        }
      }).then(rsp => {
        me.remainList = rsp.remainList;
      })
    },
    orderPop(){
      this.showQrPop = true;
    },
    //收藏事件响应事件
    collect(){
      this.isCollect == 1 ? this.cancelCollect() : this.addCollect();
    },
    addCollect(){//添加收藏
      const me = this;
      me.$post({
        url: me.apis.addCollect,
        params:{
          token:me.$global.token,
          bizType: me.bizType,
          bizId: me.scenicKey
        }
      }).then(rsp => {
        me.isCollect = 1;
        // me.loadScenic();
        // me.$emit('collect', this, 'add');
      })
    },
    cancelCollect(){//取消收藏
      const me = this;
      me.$post({
        url: me.apis.cancelCollect,
        params:{
          token: me.$global.token,
          bizType: me.bizType,
          bizId: me.scenicKey
        }
      }).then(rsp => {
        me.isCollect = 0;
        // me.loadScenic();
        // me.$emit('collect', this, 'cancel');
      });
    },
    goComment(){
      this.$router.push({
        path:'/commentList',
        query:{
          bizId:this.scenicKey
        }
      })
    },
    //获取评论
    getComment(){
      let me = this;
      me.$post({
        url:me.apis.slicedQueryUserCriticism,
        params: {
          bizId: me.scenicKey,
          bizType: me.bizType,
          token: me.$global.token
        }
      }).then(res => {//thirdName
        me.commentArr = res.data.data;
      });
    },
    //立即预定
    goOrder(){
      let _t = this;
      if(_t.dateArr.length && !_t.cDate){
        if(!_t.commodityIdFinal) return Toast('请选择日期');
      }
      if(_t.timeArr.length && !_t.cTime){
        if(!_t.commodityIdFinal) return Toast('请选择时间段');
      }
      // let pointUseSwitch = 0, creditPointsNum = 0; // 是否能使用积分     使用积分可兑换金额
      // if(_t.timeArr.length){
      //   for(let i = 0; i < _t.timeArr.length; i++){
      //     if(_t.timeArr[i].commodityId == _t.commodityIdFinal){
      //       pointUseSwitch = _t.timeArr[i].pointUseSwitch
      //       creditPointsNum = _t.timeArr[i].creditPointsNum
      //     }
      //   }
      // }
      // if(pointUseSwitch == 0){
      //   pointUseSwitch = false
      // }
      // else{
      //   pointUseSwitch = true
      // }
      sessionStorage.clear();
      sessionStorage.setItem('LOGIN_STATUS',2);
      sessionStorage.setItem('token',this.$global.token);
      let isSnow = _t.snowId == _t.scenicKey ? 1 : 0;
      sessionStorage.setItem('isSnow',isSnow);
      let path = isSnow == 1 ? '/submitOrderSnow' : '/submitOrder';
      // let path = '/submitOrder';
      this.$router.push({
        path:path,
        query:{
          name:_t.buyTitle,
          id:_t.commodityIdFinal,
          d:_t.cDate.replace(/-/g,':'),
          t:_t.cTime,
          p:_t.cPrice,
          n:_t.typeName,
          r:_t.remark,
          isSnow:isSnow,
          // i: pointUseSwitch,
          // u: creditPointsNum,
          self: _t.scenic.isSelfSupport,
          realName: _t.scenic.isRealName
        }
      })
    },
    moreDate(){
      this.showDatePicker = true
    },
    //日期弹窗确认
    confirmDate(date){
      let _t = this;
      _t.dateArr.map((item,index) => {
        let itemDate = '';
        if(isiOS){
          itemDate = new Date(item.date.replace(/-/g,'/')+' 00:00:00').getTime();
        }else{
          itemDate = new Date(item.date+' 00:00:00').getTime();
        }
        if(new Date(date).getTime() === itemDate){
          // _t.chooseDate = index
          _t.dateClick(index,item)
        }
      });
      this.showDatePicker = false
    },
    //点击时间段
    timeClick(index,row){
      let _t = this;
      _t.chooseTime = index;
      _t.cTime = row.timeSlot;
      _t.commodityIdFinal = row.commodityId;
      _t.cPrice = row.sellPrice;
      _t.cTimeId = row.commodityId;
      _t.findPriceCalendarByCommodityId(row,1);

    },
    //点击日期
    dateClick(index,row){
      this.chooseDate = index;
      document.getElementById('date'+index).scrollIntoView();
      let dateStr = isiOS ? row.date.replace(/-/g,'/')+' 00:00:00' : row.date+' 00:00:00';
      this.cPrice = row.sellPrice;
      this.cTimeId = row.commodityId;
      this.selectDate(dateStr);
    },
    selectDate(date){
      let _t = this;
      let d = new Date(date);
      let m = (d.getMonth()+1)<10 ? '0'+(d.getMonth()+1) : (d.getMonth()+1);
      let day = (d.getDate())<10 ? '0'+(d.getDate()) : (d.getDate());
      _t.cDate = d.getFullYear()+'-'+m+'-'+day;
      _t.queryThirdSystemCode(new Date(date).formatNoS())
      this.timeDate = new Date(date).formatNoS();
    },
    //渲染价格自定义文案
    formatter(day) {
      let _t = this;
      day.type = 'disabled';
      _t.dateArr.map((item,index) => {
        let itemDate = '',
          dayDate = new Date(day.date).getTime(),
          nowDate = new Date().getTime(),
          cDate = '';
        if(isiOS){
          itemDate = new Date(item.date.replace(/-/g,'/')+' 00:00:00').getTime();
          cDate = _t.cDate ? new Date(_t.cDate.replace(/-/g,'/')+' 00:00:00').getTime() : '';
        }else{
          itemDate = new Date(item.date+' 00:00:00').getTime();
          cDate = _t.cDate ? new Date(_t.cDate+' 00:00:00').getTime() : '';
        }
        if(dayDate === itemDate){
          if(!day.bottomInfo){
            day.bottomInfo = item.sellPrice ? '￥'+item.sellPrice/100 :'￥0';
          }
          if(dayDate>nowDate){
            day.type = '';
          }
          if(cDate === itemDate){
            day.type = 'selected';
          }
        }
      });
      return day;
    },
    //点击预定
    order(name,row,commodityTypeId){
      let me = this;
      me.dateArr = [];
      me.timeArr = [];
      me.typeName = name;
      me.chooseTime = null;
      me.chooseDate = null;
      me.remark = row.remark;
      me.cTimeId = row.commodityId;
      me.cPrice = row.sellPrice;
      me.commodityTypeId = commodityTypeId;
      me.findPriceCalendarByCommodityId(row)
    },
    //时间段
    queryThirdSystemCode(date){
      let me = this;
      me.chooseTime = null;
      me.cTime = null;
      me.commodityIdFinal = null;
      // me.cPrice = null;
      // me.cTimeId = null;
      me.$get({
        url:me.apis.tickets,
        params: {
          parentId: me.cTimeId,
          commodityTypeId: me.commodityTypeId,
          token: me.$global.token,
          searchTime:date.split(' ')[0],
        }
      }).then(rsp => {//thirdName
        me.timeArr = rsp.data;
        if(!rsp.data.length){
          me.commodityIdFinal = JSON.parse(JSON.stringify(me.cTimeId));
        }else{
          me.timeArr.map((item,index) => {
            item.dis = null;
            let itemDate = null;
            if(isiOS){
              itemDate = new Date(date.replace(/-/g,'/')+ ' '+item.timeSlot.split('-')[1]).getTime();
            }else{
              itemDate = new Date(date+ ' '+item.timeSlot.split('-')[1]).getTime();
            }
            let isSnow = me.snowId == me.scenicKey ? 1 : 0;
            if(isSnow){
              itemDate = itemDate.split(' ')[0]
            }
            if(itemDate<new Date().getTime()){
              item.dis = true
            }
          })
        }
      });
    },
    // 价格日历
    findPriceCalendarByCommodityId(row,num){
      let me = this;
      me.$get({
        url:me.apis.findPriceCalendarByCommodityId,
        params: {
          commodityId: row.commodityId,
          token: me.$global.token,
          pageSize: 15,
          startDate:me.timeDate
        }
      }).then(rsp => {
        if(num){
          me.cPrice = rsp.data[0].sellPrice;
        }else{
          if(rsp.data.length){
            let allDateArr = [];
            rsp.data.map((item,i) => {
              let dis = false;
              let tStr = '';
              if(isiOS){
                // var t = new Date(time.replace(/-/g,'/')).getTime();
                tStr = new Date((item.startDate+' '+'23:59:59').replace(/-/g,'/')).getTime()
              }else{
                tStr = new Date(item.startDate+' '+'23:59:59').getTime()
              }
              if(tStr<new Date().getTime()){
                dis = true;
              }
              let obj = {
                date:item.startDate,
                dis:dis,
                commodityId:item.commodityId,
                balancePrice:item.balancePrice,
                commodityName:item.commodityName,
                // endDate:item.endDate,
                priceCalendarId:item.priceCalendarId,
                priorityLevel:item.priorityLevel,
                sellPrice:item.sellPrice,
                sellerType:item.sellerType,
                shopPrice:item.shopPrice,
                startDate:item.startDate,
                typeId:item.typeId,
              };
              allDateArr.push(obj)
            });
            me.dateArr = JSON.parse(JSON.stringify(allDateArr));
            me.buyTitle = row.name;
            me.showBuyDate = true;
          }else{
            me.commodityIdFinal = row.commodityId;
            Toast('该商品暂无库存！')
          }
        }
      });
    },
    // 获取景区详情
    getDetail(){
      this.$get({
        url:this.apis.scenic,
        params: {
          sellerId: this.scenicKey,
          token: this.$global.token
        }
      }).then(rsp => {
       console.log(rsp);
       this.depiction = rsp.depiction
      });
    },
    //跳转到景区详情
    goIntro(path,num){
      if(num == 0){
        if(!this.$global.token) return this.isShowLoginPop = true;
        sessionStorage.setItem('orderList',1)
      }
      this.$router.push({
        path:path,
        query:{
          key:this.scenicKey,
          type:this.bizType,
          orderType:num,
        }
      })
    },
    //改变轮播获取当前index
    onChangeVanSwipe(index){
      this.current = index;
    },
    // 头部颜色
    scrollFunc(){
      let top = document.getElementById('app').scrollTop||document.documentElement.scrollTop;
      if(top>30){
        this.colorState = true
      }else{
        this.colorState = false
      }
    },
    // 导航
    goMap(){
      // let arr = this.$global.loactions.split(',')
      let isMap = JSON.parse(localStorage.getItem('map')) ? true : false;
      if(!isMap) return Toast('定位失败，无法导航');
      let locations = JSON.parse(localStorage.getItem('map')).position;
      let ipGo=null;
      // ipGo = `https://uri.amap.com/navigation?from=${locations.lng},${locations.lat},起点&to=${this.scenic.longitude},${this.scenic.latitude},${this.scenic.name}&callnative=1`
      ipGo = `https://www.amap.com/dir?from[name]=起点&from[lnglat]=${locations.lng},${locations.lat}&to[name]=${this.scenic.name}&to[lnglat]=${this.scenic.longitude},${this.scenic.latitude}&policy=1&type=car&callnative=1`

      // window.open(ipGo)
      this.$router.push({
        path:'/outWindow',
        query:{
          interlinkage:encodeURI(ipGo)
        }
      })
    },
    // 返回
    revert(num){//返回安卓原生页面或者h5页面
      // alert(JSON.stringify(this.$route.query))
      if(this.$route.query.share == 1){
        this.$router.push('/');
      }else{
        this.$router.go(-1);
      }
    },
    toggleContent(){//切换介绍是否完整展示的状态
      this.expandContent = !this.expandContent;
    },
    //获取景点详情
    loadScenic(){
      let me = this;
      me.scenic = {};
      me.imgs = [];
      me.$get({
        url:me.apis.scenic,
        params: {
          sellerId: me.scenicKey,
          token: me.$global.token
        }
      }).then(rsp => {
        me.scenic = rsp;
        let shareUrl = window.location.href.split('#')[1];
        if(JSON.stringify(this.$route.query) === '{}'){
          shareUrl = shareUrl+'?share=1'
        }else{
          shareUrl = shareUrl+'&share=1'
        }
        me.getShareInfo(
          me.scenic.name,
          this.$global.visitUrl+'#'+shareUrl,
          me.scenic.cover,
          me.scenic.introduce.replace(/<\/?.+?\/?>/g,''),
        );
        if(me.scenic.sellerWebsite){

          sessionStorage.setItem('outWeb',me.scenic.sellerWebsite)
        }
        me.isCollect = me.scenic.isCollect;
        if(rsp && rsp.attachments && rsp.attachments.length > 0){
          var files = rsp.attachments;
          for(var i = 0, n = files.length; i < n; i++){
            if(files[i].fileType && files[i].fileType == 1){
              me.imgs.push(files[i].url);
            }else if(files[i].fileType && files[i].fileType == 2){
              me.videos.push(files[i].url);
            }
          }
        }
        if(me.imgs.length <= 0){
          me.imgs.push(me.scenic.cover);
        }
        if(me.$route.query.priceCalendarCount == 0 && (me.scenic.sellerQrcodeUrl || me.scenic.sellerWebsite)){
          me.getRemainTicket(me.scenic.sellerThirdCode)
        }

      });
    },
    loadTypes(){//获取门票类型
      let me = this;
      me.$get({
        url:me.apis.types,
        params: {
          sellerId: me.scenicKey,
          token: me.$global.token
        }
      }).then(rsp => {
        me.types = rsp;
        if(rsp && rsp.length > 0){
          rsp.map((item,index) => {
            me.loadTickets(item.commodityTypeId,index,item.name);
          })
        }
      });
    },
    async loadTickets(commodityTypeId,index,name){//获取商家的门票列表
      let me = this;
      let data = await me.$get({
        url:me.apis.tickets,
        params: {
          parentId:0,
          commodityTypeId:commodityTypeId,
          sellerId:me.scenicKey,
        }
      });
      me.tickets[index] = {
        name:name,
        commodityTypeId:commodityTypeId,
        isShow:false,
        data:data.data
      };
      me.tickets = JSON.parse(JSON.stringify(me.tickets));
    },
    play(){//播放或暂停播放
      let me = this;
      if(!me.$global.audio){
        me.$global.audio = new Audio(me.scenic.audiobook);
        if(me.playing){
          me.$global.audio.pause()
        }else{
          me.$global.audio.play()
        }
        me.playing = !me.playing;
      }else{
        if(me.scenic.audiobook == me.$global.audio.src){
          if(me.playing){
            me.$global.audio.pause()
          }else{
            me.$global.audio.play()
          }
          me.playing = !me.playing;
        }else{
          me.$global.audio.src = me.scenic.audiobook;
          me.$global.audio.play();
          me.playing = true;
        }
      }
    },

    praiseOrCollect(){//点赞或收藏后触发
      this.loadScenic();
    },
    showDetails(row) {
      if(row.depiction != ''){
        this.remarkShow = true
        this.sheetContent.title = row.name
        this.sheetContent.depiction = row.depiction
      }
    },
  }
}
